import React from 'react'

const Byline = ({content, classes, children, color = 'text-red-100'}) => (
  <div
    className={[
      'mt-3',
      'text-sm',
      'font-semibold',
      color,
      'leading-7',
      'max-w-2xl',
      'sm_mt-4',
      classes,
    ].join(' ')}
  >
    {content || children}
  </div>
)

export default Byline
