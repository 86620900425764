import React from 'react'
import Heading from '@/components/Heading'
import Byline from '@/components/Byline'

const Header = ({title, date, children, ...props}) => {
  return (
    <header className="text-center align-center sm_mx-auto sm_w-full sm_max-w-2xl">
      <Heading classes={props.className}>{title}</Heading>
      <Byline>{date}</Byline>
      {children}
    </header>
  )
}

export default Header
