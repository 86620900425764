import React from 'react'
import Header from '@/components/Header'
import Link from 'next/link'

const Custom404 = () => (
  <div className="relative px-4 py-24 sm_px-6 sm_py-48 lg_py-96 lg_px-8">
    <Header title="404 Not Found" />
    <p className="mt-12 text-sm text-center text-red-100 max-w">
      <Link
        href="/"
        className="font-medium text-white underline cursor-pointer hover_text-black transition durantion-150 ease-in-out">
        
          Get me out of here
        
      </Link>
    </p>
  </div>
)

export default Custom404
