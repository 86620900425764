import React from 'react'

const Heading = ({content, children, classes}) => (
  <h2
    className={[
      'mt-6',
      'text-3xl',
      'font-extrabold',
      'text-white',
      'leading-9',
      'sm_text-4xl',
      'sm_leading-10',
      classes,
    ].join(' ')}
  >
    {content || children}
  </h2>
)

export default Heading
